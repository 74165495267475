<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.arror = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col
          ><h2>Application</h2></v-col
        >
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div class="pr-2">
              <v-btn width="120px" v-if="student && student.status == 'UNDER_REVIEW'"  rounded outlined color="error" :to="{name:'PageEducatorInstitutionSponsoredStudentCancel',params:{id:this.$route.params.id,parentId:this.$route.params.parentId}}"> Terminate</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ALoader :isLoading="api.isLoading"></ALoader>
      <Read v-if="!api.isLoading && student" :headers="headers" :data="student" />
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import Read from "../../components/Datatable/Read.vue";
export default {
  components: {
    Read
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    gameUrl: null,
    totalPage: 0,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      // {
      //   text: "Id",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Avatar", value: "avatar" },
      // { text: "Month", value: "month" },
      { text: "Name", value: "Student.name" },
      { text: "Classroom", value: "Student.Classroom.name" },
      { text: "Status", value: "sponsoredStudentstatus" },
      { text: "Start Date", value: "timestampStart" },
      { text: "End Date", value: "timestampEnd" },
      { text: "Applied", value: "timestampCreated" },
      { text: "Applied By", value: "sponsoredStudentAppliedBy" },
      { text: "Applied Reason", value: "appliedReason" },
      { text: "Rejected", value: "timestampRejected" },
      { text: "Rejected By", value: "rejectedByModeratorName" },
      { text: "Rejected Reason", value: "sponsoredStudentRejectedReason" },
      { text: "Terminated", value: "timestampCancelled" },
      { text: "Terminated By", value: "cancelledByUserName" },
      { text: "Terminated Reason", value: "sponsoredStudentTerminatedReason" },
      { text: "Last Update", value: "timestampUpdated" },
    ],
    data: [],
    student: {},
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Hamochi Care",
        to: {
          name: "PageEducatorInstitutionSponsoredStudent",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Info",
        to: { name: "PageEducatorInstitutionSponsoredStudentRead" },
        exact: true,
      },
    ];
    //EOC
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/sponsoredStudent/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.student = resp;
      if(this.student.status != 'REJECTED'){
        delete this.headers[9]
        delete this.headers[10]
        delete this.headers[11]
      }
      if(this.student.status != 'CANCELED'){
        delete this.headers[12]
        delete this.headers[13]
        delete this.headers[14]
      }
      this.data.push(this.student);
    };

    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/classroom/changeStatus";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$api.fetch(this.api);
    };


    //EOC
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    changeStatus(status){
      this.editApi.params = {id: this.$route.params.id, isActive:status}
      this.$api.fetch(this.editApi);
    }
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>